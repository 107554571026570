<template lang='pug'>
Error(v-if="error", :error="error")
Loader(v-else-if="loading", :info="loading")
.redirect(v-else-if="payment")
  .top
    img(alt="Delooye logo", src="@/assets/logo_small.png") 
    h1 Delooye Payment
  .bottom(v-if="payment.status === 'canceled'")
    h1 Payment Canceled
    h2 Your payment was canceled and can no longer be finished. Please contact Delooye at info@delooye.com.
  .bottom(v-else-if="payment.status === 'pending'")
    h1 Payment Pending
    h2 If you finished the payment, you can reload this page to check the status again. If your payment process got interrupted, you can continue here:
    button(@click="toPayPage()") Continue Payment
      i.fas.fa-arrow-right
  .bottom(v-else-if="payment.status === 'paid'")
    h1 Payment Successful
    h2 Thank you for using Delooye Payment.
  .test-mode(v-if='id.startsWith("test_")') 
    i.fas.fa-exclamation-circle
    span Let op: dit is een testmode-betaling
Error(v-else, :error="'Something went wrong, please try again later.'")
</template>

<script>
import Loader from "../components/Loader.vue";
import Error from "../components/Error.vue";
import axios from "axios";

export default {
  props: {
    id: String,
  },
  components: {
    Loader,
    Error,
  },
  data: function () {
    return {
      error: null,
      loading: null,
      payment: null,
      retryCounter: 0,
    };
  },
  mounted() {
    this.getPayment();
  },
  methods: {
    getPayment() {
      this.loading = "Retrieving payment...";
      axios
        .get(`https://payment.delooye.com/api/${this.id}/status`)
        .then((res) => {
          if (res.data) {
            if (res.data.status === "pending") {
              if (this.retryCounter < 5) {
                setTimeout(() => this.getPayment(), 1000);
                this.retryCounter++;
              } else {
                this.payment = res.data;
                this.loading = null;
              }
            } else if (res.data.status === "open") {
              this.$router.push({ path: `/${this.id}/pay` });
            } else {
              this.payment = res.data;
              this.loading = null;
            }
          } else {
            this.error = "Something went wrong, please try again later.";
            this.loading = null;
          }
        })
        .catch((e) => {
          if (e.response?.status) {
            if (e.response.status === 400) {
              this.error =
                "Invalid payment ID. Please contact Delooye at info@delooye.com.";
              this.loading = null;
            } else if (e.response.status === 404) {
              this.error =
                "We could not find your payment. Please contact Delooye at info@delooye.com.";
              this.loading = null;
            } else {
              this.error = "Something went wrong, please try again later.";
              this.loading = null;
            }
          } else {
            this.error = "Something went wrong, please try again later.";
            this.loading = null;
          }
        });
    },
    toPayPage() {
      this.$router.push({ path: `/${this.id}/pay` });
    },
  },
};
</script>
<style lang="scss" scoped>
.redirect {
  height: 100%;
  width: 100%;
}
h2 {
  max-width: 750px;
}
</style>